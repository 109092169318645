@use "sass:math";
// $danger: #d9534f;
// $google: #de5246;
// $primary: #370e62;
$primary: #6f42c1; // purple
// $primary: #6610f2; // indigo
// $secondary: #f5c300;
$secondary: #b6007d;
// $bg-primary-light: #d7cfe0;
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/utilities";
// @import "~bootstrap/scss/grid";

// React Big Calendar Overrides
$out-of-range-bg-color: var(--bs-warning-bg-subtle) !default;
$today-highlight-bg: var(--bs-primary-bg-subtle);
// $myBorderColor: #adb5bd;
$myBorderColor: #ced4da;
$calendar-border: $myBorderColor !default;
$cell-border: $myBorderColor !default;
$active-background: hotpink !default;
$active-border: hotpink !default;
$current-time-color: #74ad31 !default;
$date-selection-bg-color: hotpink;
$event-bg: $secondary;
$event-border-radius: 5px !default;
$event-border: none;
$event-color: #fff !default;
$event-outline: none;
$event-margin: 0px 12px !default;
$event-padding: 2px 5px !default;
$event-zindex: 4 !default;
$rbc-css-prefix: rbc-i !default;
$segment-width: math.percentage(math.div(1, 7)) !default;
$time-selection-bg-color: hotpink;
$time-selection-color: white !default;
