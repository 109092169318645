
// turn off number spinners
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}

// Alerts
.user-alert-title {
    font-size: 0.875rem;
}
.user-alert-message {
    font-size: 0.875rem;
}
.hover-opacity:hover {
    opacity: 1;
}

// Animation
.rotate {
    animation: rotation 4s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

// This is to prevent the underline persisting after click
// .btn-link:focus {
//     text-decoration: none;
//     border: none;
//     outline: none;
//     box-shadow: none;
// }
// .btn-link:hover:focus {
//     text-decoration: underline;
//     border: none;
//     outline: none;
//     box-shadow: none;
// }

.custom-cancel-button,
.custom-link-button,
.custom-link-button-primary {
    @extend .btn-link;
    text-decoration: none;
}

.custom-link-button-danger {
    @extend .btn-link;
    color: var(--bs-danger);
    text-decoration: none;
}

.custom-link-button-success {
    @extend .btn-link;
    color: var(--bs-success);
    text-decoration: none;
}

.custom-link-button-warning {
    @extend .btn-link;
    color: var(--bs-warning);
    text-decoration: none;
}

.icon-button {
    // @extend .btn-link;
    color: var(--bs-link-color) !important;
    text-decoration: none;
}

.icon-button-danger {
    @extend .btn-link;
    color: var(--bs-danger) !important;
    padding: 0px 0px 0px 0px;
    text-decoration: none;
}

.icon-button-muted {
    @extend .btn-link;
    color: var(--bs-secondary-body) !important;
    padding: 0px 0px 0px 0px;
    text-decoration: none;
}

.icon-button-primary {
    @extend .btn-link;
    color: var(--bs-link-color) !important;
    padding: 0px 0px 0px 0px;
    text-decoration: none;
}

.icon-button-secondary {
    @extend .btn-link;
    color: var(--bs-secondary) !important;
    padding: 0px 0px 0px 0px;
    text-decoration: none;
}

.icon-button-warning {
    @extend .btn-link;
    color: var(--bs-warning) !important;
    padding: 0px 0px 0px 0px;
    text-decoration: none;
}

$button-opacity: 0.7;
.icon-button-danger:hover,
.icon-button-muted:hover,
.icon-button-primary:hover,
.icon-button-secondary:hover {
    // color: $btn-link-hover-color !important;
    opacity: $button-opacity;
}

// .custom-cancel-button:hover,
// .custom-link-button:hover {
//     color: $link-color !important;
// }

.center-vertical-with-nav {
    justify-content: center;
    min-height: calc(100vh - 56px);
}

.display {
    @extend .display-1;
    font-size: 1.5rem;
}

.no-focus-decoration {
    outline: none;
}

// This removes the arrow next to the react-bootstrap dropdown menu
.dropdown-toggle::after {
    display: none !important;
}
.no-height {
    display: none;
    height: 0;
}

.popover-link {
    @extend a;
    text-decoration: none;
}

// .popover-link:hover {
//     color: $secondary;
// }

.semi-strong {
    font-weight: 500;
}

// header
.header-image {
    height: 180px;
    width: auto;
}

.header-title {
    @extend .padthai-4;
}

.immigration-event-text {
    @extend .lead;
    font-size: 1rem;
}

// Nav Bar
.navbar-toggler:focus {
    outline: 0;
}

.no-selected-event-forms {
    justify-content: center;
    min-height: calc(100vh - 56px);
}

.padthai-1 {
    @extend .display-1;
    font-size: 3rem;
    font-family: PhadThai;
}
.padthai-4 {
    @extend .display-4;
    font-size: 1.875rem;
    font-family: PhadThai;
}

.formikErrorMessage {
    color: $danger;
    font-size: 0.875rem;
    margin-top: 4px;
    // padding-left: 0.5rem;
}

// React Bootstrap Typeahead
.my-rbt-aux {
    @extend .rbt-aux;
    right: 10px;
    width: auto;
}
.rbt-loader {
    display: none;
}

// Tutorial
.carousel-control-icon {
    font-size: 1.25rem;
}

.carousel-indicators li {
    border-top: $primary;
    background-color: $primary;
}

.carousel-indicators .active {
    border-top: darken($color: $primary, $amount: 5);
}

.tutorial-image {
    margin-bottom: 16px;
    height: "160px";
    width: "auto";
}

.tutorial-lead {
    @extend .lead;
    font-size: 1rem;
}

.tutorial-page {
    margin-left: 32px;
    margin-right: 32px;
}

.tutorial-title {
    @extend .display-1;
    font-size: 28px;
    margin-bottom: 18px;
}

@include media-breakpoint-up(sm) {
    .display {
        font-size: 2.5rem;
    }
    .header-image {
        height: 240px;
        width: auto;
    }

    .header-title {
        font-size: 3.5rem;
    }

    .immigration-event-text {
        font-size: 1.25rem;
    }
    .padthai-1 {
        font-size: 6rem;
    }
    .padthai-4 {
        font-size: 3.5rem;
    }
}

@include media-breakpoint-up(sm) {
    // Alerts
    .user-alert-title {
        font-size: 1rem;
    }
    .user-alert-message {
        font-size: 1rem;
    }
    // Tutorial
    .carousel-control-icon {
        font-size: 2rem;
    }

    .carousel-indicators li {
        border-top: $primary;
        background-color: $primary;
    }

    .carousel-indicators .active {
        border-top: darken($color: $primary, $amount: 5);
        // background-color: darken($color: $primary, $amount: 5);
    }

    .tutorial-image {
        margin-bottom: 16px;
        height: "400px";
        width: "auto";
    }

    .tutorial-lead {
        font-size: 1.25rem;
    }

    .tutorial-page {
        margin-left: 16px;
        margin-right: 16px;
        width: 500px;
    }

    .tutorial-title {
        font-size: 36px;
        margin-bottom: 18px;
    }
}

@include media-breakpoint-up(md) {
    .display {
        font-size: 3.5rem;
    }
    .header-image {
        height: 240px;
        width: auto;
    }

    .header-title {
        font-size: 3.5rem;
    }

    .immigration-event-text {
        font-size: 1.25rem;
    }
    .padthai-1 {
        font-size: 6rem;
    }
    .padthai-4 {
        font-size: 3.5rem;
    }
    .rbc-toolbar {
        flex-direction: row;
        align-items: center;
    }
}
