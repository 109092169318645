.rbc-toolbar {
    flex-direction: column;
    align-items: center;
}
.rbc-toolbar-label {
    @extend .rbc-toolbar-label;
    @extend .lead;
    font-weight: 400;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
$active-background: darken($btn-bg, 10%);
$active-border: darken($btn-border, 12%);

.rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;

    .rbc-toolbar-label {
        flex-grow: 1;
        padding: 0 10px;
        text-align: center;
    }

    & button {
        color: var(--bs-link-color);
        display: inline-block;
        margin: 0;
        text-align: center;
        vertical-align: middle;
        background: none;
        background-image: none;
        border: 1px solid $primary;
        padding: 0.375rem 1rem;
        border-radius: 4px;
        line-height: normal;
        white-space: nowrap;

        &:active,
        &.rbc-active {
            color: white;
            background-image: none;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            background-color: $primary;
            border-color: $primary;

            // &:focus,
            &:focus {
                color: white;
                background-color: $primary;
                border-color: $primary;
            }
            &:hover {
                color: darken($white, 25%);
                background-color: darken($primary, 15%);
                border-color: darken($primary, 15%);
            }
        }

        &:focus {
            color: white;
            background-color: $primary;
            border-color: $primary;
        }

        &:hover {
            color: darken($white, 25%);
            background-color: darken($primary, 15%);
            border-color: darken($primary, 15%);
        }
    }
}

.rbc-agenda-event-cell {
    @extend .btn-link;
    color: var(--bs-link-color);
    cursor: pointer;
    text-decoration: none;
}

.rbc-row-segment {
  padding: 0 8px 8px 8px;

  .rbc-event-content {
    @extend .rbc-ellipsis;
  }
}
